<template>
	<main>
		<HeaderTab :title="componentTitle" :return_action="'return_action'" />
		<div id="content">
			<div class="container-fluid">
				<div class="row">
					<div class="col-12">
						<div class="box">
							<!-- Choix du/des chevaux sur le(s)quel(s) ajouter le mouvement -->
							<div v-if="!mouvement_id" v-show="state == 'mouvementAjout'"><!-- Si on a un mouvement_id, pas besoin d'afficher la séléction des chevaux -->
								<ButtonValidation @btn-onclick="nextStep" title="global.suivant" icon="suivant" :disabled="!canGoNext" />
								<HorseSelection v-if="needLoadHorses" :selected_horses_ids.sync="checked_horses" />
							</div>
							<router-view v-show="routerViewState"></router-view>

							<div v-if="state == 'traitement'">
								<LoadingSpinner />
							</div>
							<div v-else-if="state == 'success'">
								<!-- changer par toast -->
								<Success />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</main>
</template>



<script type="text/javascript">
	import { EventBus } from 'EventBus';
	import Navigation from "@/mixins/Navigation.js";
	import Mouvements from "@/mixins/Mouvements.js";
	import Lieux from "@/mixins/Lieux.js";
	import Horse from "@/mixins/Horse.js";

	export default {
		name: "mouvementAjout",
		mixins: [Lieux, Mouvements, Horse, Navigation],
		data () {
			return {
				mouvement_id: null,
				horse_ids: null, /* Array d'ids de chevaux (transmis dans les routes sous forme de liste séparée par des virgules) */
				componentTitle: '',
				tab_states: {
					'mouvementAjout': 'global.selectionnez_chevaux',
					'WriteMouvement': 'mouvement.ajouter_mouvement',
					'traitement': '',
					'success': ''
				},
				defaultComponentTitle: 'mouvement.ajouter_mouvement',

				mouv_modif_title: 'mouvement.modifier_mouvement',
				state_index: 0,
				state: '',
				checked_horses: [],
				overridden_back: '',
				actual_component_name: '',
				from_asset: '', // vue / composant depuis lequelle la route a été push pour arriver jusqu'ici
				items: null,
				events_tab: {
					'MouvementAjout::updateHeaderTitle': this.updateHeaderTitle,
					'MouvementAjout::disableGlobalNav': this.disableGlobalNav,   // => permet d'indiquer à la vue d'envoyer un event, donné en param, au clic sur le bouton retour
					'MouvementAjout::genericReturnData': this.genericReturnData, // => event bus par lequel sont envoyées les données du formulaire de mouvement
				}
			}
		},
		mounted() {
			// On récupère les éventuels params
			this.mouvement_id = this.$route.params.mouvement_id
			this.horse_ids = (this.$route.params.horse_id !== undefined ? String(this.$route.params.horse_id).split(",") : [] )
			this.checked_horses = [...this.horse_ids]
			this.from_asset = this.$route.params.from
			this.actual_component_name = this.$route.name
			this.first_mouv = this.$route.params.first_mouv

			this.init_component()
		},
		methods: {
			async init_component() {
				if(this.autoNextStep && this.$route.name !== 'WriteMouvement') {
					this.$router.replace({ name: 'WriteMouvement', params: { horse_id: this.horseIdRouteParam, mouvement_id: this.mouvement_id, from: this.from_asset }})
				}

				this.component_name = "mouvementAjout" // Nom du router
				this.set_actual_state_from_route(this.$route.name)
			},

			// Validation du formulaire
			async valid_form(data) {
				const success = await this.processMouvOperations(data, this.checked_horses, this.mouvement_id, this.hasNoPreviousMouv)

				if(!success) {
					this.failureToast('toast.info_save_failed')
					this.set_last_state()
					return
				}

				this.successToast('toast.info_save_succes')
				this.redirectPostSave()
			},

			/* Nav et header title */
			return_action() {
				// Si on a un event à envoyer, au clic sur le bouton retour
				if(this.overridden_back) {
					EventBus.$emit(this.overridden_back, '')
				}
				// Sinon, on fait revient simplement dans l'historique de $route
				else {
					return this.goBack()
				}
			},
			/* Event Bus */
			updateHeaderTitle(step_name) {
				if(step_name) {
					this.componentTitle = this.getTrad(step_name)
				}
				else {
					this.set_actual_status()
				}
			},
			disableGlobalNav(send_to) {
				// Si on a une adresse d'envoie d'event, on le garde de côté, sinon on raz
				if(send_to) {
					this.overridden_back = send_to
				}
				else {
					this.overridden_back = ''
				}
			},
			// En fonction de la route actuelle, on a traiter les données de différentes manières
			genericReturnData(data) {
				// Si les données viennent du formulaire d'ajout direct de mouvement, on enregistre un mouvement avec ces infos
				if(this.actual_component_name == 'WriteMouvement') {
					this.set_next_state()
					this.valid_form(data)
				}
			},
			nextStep() {
				if(this.canGoNext) {
					this.set_next_state()

					// Si on a plusieurs chevaux sélectionnés, on notifie la vue suivante qu'aucune date de retour n'est paramétrable
					const horse_id_checked = this.checked_horses[0]
					const horse_id_param = this.canDefineRetour ? horse_id_checked : -1
					this.$router.push({ name: 'WriteMouvement', params: { horse_id: horse_id_param, from: this.from_asset }})
				}
			},
			redirectPostSave() {
				if(this.from_asset == 'horseAjout' || this.from_asset == 'HorseCreation') {
					this.$router.push({ name: 'horseFiche', params: { horse_id: this.checked_horses.join(',') } })
				}
				else if(this.checked_horses && this.from_asset == 'HorseFicheMouvements')
					this.$router.push({ name: 'HorseFicheMouvements', params: { horse_id: this.checked_horses.join(',') } })
				else
					this.$router.push({ name: 'mouvementListe' })
			},
		},
		computed: {
			autoNextStep() {
				return this.from_asset == "horseAjout" 
					|| this.from_asset == "HorseCreation" 
					|| (this.from_asset == "horseListe" && this.canGoNext)
					|| this.from_asset == "mouvementResidence" 
					|| (this.from_asset == "horseSearch" && this.canGoNext)
					|| (this.from_asset == "HorseFicheMouvements" && !this.mouvement_id)
			},
			canGoNext() {
				return this.checked_horses.length > 0
			},
			canDefineRetour() {
				return this.checked_horses.length == 1
			},
			checkedHorsesQuantity() {
				return this.checked_horses.length
			},

			// Si horse_id est un tableau, on retourne que le premier
			horseIdRouteParam() {
				return this.horse_ids.length > 1 ? -1 : this.horse_ids[0]
			},
			needLoadHorses() {
				return !this.autoNextStep
			},
			routerViewState() {
				return this.state !== 'traitement' && this.state !== 'success'
			},
			hasNoPreviousMouv() {
				const whitelist = ["horseAjout", "HorseCreation"]
				if(whitelist.indexOf(this.from_asset) > -1) {
					return true
				}
				if(this.first_mouv) {
					return true
				}
				return false
			}
		},
		watch: {
			'$route' (to, from) {
				this.actual_component_name = to.name
				this.set_actual_state_from_route(to.name)

				// Si l'url revient sur ce composant, on reprend le dernier état, pour réafficher les div
				if(to.name == this.component_name && from.name == "WriteMouvement") {
					if(this.from_asset == "horseAjout" || this.from_asset == "HorseCreation") {
						this.$router.push({ name: 'horseFiche', params: { horse_id: this.horse_ids.join(',') } })
					}
					else if(this.from_asset == "horseListe") {
						this.$router.push({ name: 'horseListe' })
					}
				}
			}
		},
		components: {
			HeaderTab: () => import('@/components/HeaderTab'),
			HorseSelection: () => import('@/components/Horse/HorseSelectionV2'),
			ButtonValidation: () => import('GroomyRoot/components/Buttons/Validation'),
			LoadingSpinner: () => import('GroomyRoot/components/Logos/LoadingSpinner_35'),
			Success: () => import('@/components/Success'),
		}
	}
</script>
